import React, { useEffect, useRef, useState } from 'react';

import KeyboardBannerKoreanImage from 'assets/images/img_keyboard_banner.png';
import KeyboardBannerEnglishImage from 'assets/images/img_keyboard_banner_en.png';
import KeyboardPopupKoreanImage from 'assets/images/img_keyboard_popup.png';
import KeyboardPopupEnglishImage from 'assets/images/img_keyboard_popup_en.png';
import { useModal } from 'hooks/useModal';
import { event } from 'react-ga';
import ReactLoading from 'react-loading';
import { LanguageType } from 'scheme/theme/themeInfo';
import styled from 'styled-components/macro';
import { theme } from 'styles/theme';
import { isDesktop } from '../../utils/isDesktop';
import CtaButton from './CtaButton';
import CloseIconImage from '../../assets/icons/ic_close.png';
import QRCodeImage from 'assets/images/img_playkeyboard_qrcode.png';
import { useTranslation } from 'react-i18next';

interface IPreUseKeyboardProps {
  themeId: string | undefined;
  languageType: LanguageType;
  shareLink: string;
  handleKeyboardModalClose: () => void;
}
interface IPreUseKeyboard {
  preUseKeyboardHeight: number;
  preUseKeyboardWidth: number;
}

interface IKeyboardBanner {
  preUseKeyboardWidth: number;
  preUseKeyboardHeight: number;
}

interface IPurchaseButton {
  preUseKeyboardHeight: number;
}

export default function PreUseKeyboard({
  shareLink,
  themeId,
  languageType,
  handleKeyboardModalClose,
}: IPreUseKeyboardProps) {
  const [preUseKeyboardHeight, setPreUseKeyboardHeight] = useState<number>(0);
  const [preUseKeyboardWidth, setPreUseKeyboardWidth] = useState<number>(0);
  const [purchaseButtonHeight, setPurchaseButtonHeight] = useState<number>(0);
  const [keyboardTypeCount, setKeyboardTypeCount] = useState<number>(0);
  const [isPopupLoading, setIsPopupLoading] = useState<boolean>(false);
  const { innerWidth: windowWidth } = window;
  const mainLayout = useRef<HTMLDivElement>(null);
  const { handleModalOpen, handleModalClose, isModalOpen } = useModal();
  const { t } = useTranslation();

  useEffect(() => {
    // 화면 크기에 따라 체험용 키보드의 크기를 설정
    if (mainLayout.current) {
      if (windowWidth >= 625) {
        setPreUseKeyboardHeight(550);
        setPreUseKeyboardWidth(650);
      } else if (windowWidth >= 450) {
        setPreUseKeyboardHeight((mainLayout.current.clientWidth + 32) * (450 / 550));
        setPreUseKeyboardWidth(windowWidth);
      } else if (windowWidth >= 330) {
        setPreUseKeyboardHeight((mainLayout.current.clientWidth + 32) * (708 / 850));
        setPreUseKeyboardWidth(windowWidth);
      } else {
        setPreUseKeyboardHeight((mainLayout.current.clientWidth + 32) * (720 / 850));
        setPreUseKeyboardWidth(windowWidth);
      }
    }
  }, []);

  useEffect(() => {
    if (mainLayout.current) {
      if (windowWidth >= 600) {
        setPurchaseButtonHeight(650 / 4 + 550 + 15);
      } else {
        setPurchaseButtonHeight(windowWidth / 4 + preUseKeyboardHeight + 15);
      }
    }
  }, [preUseKeyboardHeight]);

  const handlePreUseKeyboardClose = () => {
    handleKeyboardModalClose();
  };

  const handleCtaButtonClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    event({
      category: 'click_goto_app_keyboard',
      action: 'Click goto_app_keyboard',
      label: themeId,
    });

    if (!isDesktop()) {
      window.open(shareLink);
    } else {
      handleModalOpen();
    }
  };

  window.onmessage = (e) => {
    // 키보드로 10자 입력 시 앱 전환 유도 팝업 띄우기 위한 함수
    if (e.data.click && keyboardTypeCount < 10) {
      setKeyboardTypeCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    // 키보드를 10회 입력 시 팝업 띄우기
    // isPopupLoading이 true이면 클릭 비활성화된 팝업이 뜨고
    // 2초 후 isPopupLoading을 false로 변경한 뒤 클릭이 활성화된 (클릭 시 앱으로 전환되는) 팝업 띄움
    // 타자를 치다가 바로 앱으로 전환되는 경험을 방지하기 위함
    if (!keyboardTypeCount) return;
    if (keyboardTypeCount === 10) {
      setIsPopupLoading(true);
      setTimeout(() => {
        setIsPopupLoading(false);
      }, 2000);
      handleModalOpen();
    }
  }, [keyboardTypeCount]);

  let qr = QRCodeImage;

  const customQRThemes = ['KAKAOGT-K-0', 'KAKAOGT-K-1', 'KAKAOGT-L-1', 'KAKAOGT-L-2', 'KAKAOGT-L-3', 'KAKAOGT-L-4', 'KAKAOGT-L-5', 'KAKAOGT-M-1', 'KAKAOGT-M-2', 'KAKAOGT-M-3', 'HY-L-0', 'HY-L-1', 'HY-L-2', 'HY-L-3', 'HY-M-0'];

  const index = customQRThemes.indexOf(themeId as string);
  if (index !== -1) {
    qr = require('assets/images/qr/'+customQRThemes[index]+'.png');
  }

  return (
    <>
      <CTAButtonContainer
        //onClick={handlePreUseKeyboardClose}
        preUseKeyboardHeight={purchaseButtonHeight}
        preUseKeyboardWidth={preUseKeyboardWidth}
      >
        <CtaButton handleCtaButtonClick={handleCtaButtonClick} paymentType="" shareLink={shareLink} />
      </CTAButtonContainer>

      <KeyboardDimmer onClick={handlePreUseKeyboardClose} ref={mainLayout}></KeyboardDimmer>
      <KeyboardBanner
        src={languageType === 'ko' ? KeyboardBannerKoreanImage : KeyboardBannerEnglishImage}
        preUseKeyboardWidth={preUseKeyboardWidth}
        preUseKeyboardHeight={preUseKeyboardHeight}
      />
      <KeyboardBackground preUseKeyboardWidth={preUseKeyboardWidth} preUseKeyboardHeight={preUseKeyboardHeight}>
        <LoadingContainer>
          <ReactLoading type="spin" color={theme.color.allMainColor} height="20%" width="20%" />
        </LoadingContainer>
      </KeyboardBackground>
      <Keyboard
        src={`https://plkey.studio/keyboard-preview/${themeId}`}
        preUseKeyboardHeight={preUseKeyboardHeight}
        preUseKeyboardWidth={preUseKeyboardWidth}
      />
      {isModalOpen && (
        <Dimmer onClick={handleModalClose}>
          <ModalContainer onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
            <CloseButtonContainer>
              <CloseButton src={CloseIconImage} alt="" onClick={handleModalClose} />
            </CloseButtonContainer>
            <QRCodeContainer>
              <QRCode src={qr} alt="" />
            </QRCodeContainer>
            <ModalBodyContainer>
              <ModalBody>{t('qrCodeText1')}</ModalBody>
              <ModalBody>{t('qrCodeText2')}</ModalBody>
            </ModalBodyContainer>
          </ModalContainer>
        </Dimmer>
      )}

    </>
  );
}

const CTAButtonContainer = styled.div<IPreUseKeyboard>`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  width: ${(props) => props.preUseKeyboardWidth - 16}px;
  bottom: ${(props) => props.preUseKeyboardHeight}px;
  z-index: 550;
`;

const KeyboardDimmer = styled.div`
  display: flex;
  justify-content: center;
  background-color: red;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(60, 60, 60, 40%);
  z-index: 200;
`;

const KeyboardBanner = styled.img<IKeyboardBanner>`
  position: fixed;
  bottom: ${(props) => props.preUseKeyboardHeight}px;
  width: ${(props) => props.preUseKeyboardWidth}px;
  z-index: 250;
`;

const KeyboardBackground = styled.div<IPreUseKeyboard>`
  position: fixed;
  display: flex;
  justify-content: center;
  width: ${(props) => props.preUseKeyboardWidth}px;
  height: ${(props) => props.preUseKeyboardHeight}px;
  bottom: 0;
  background-color: ${theme.color.basicWhite};
  z-index: 210;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Keyboard = styled.iframe<IPreUseKeyboard>`
  width: ${(props) => props.preUseKeyboardWidth}px;
  height: ${(props) => props.preUseKeyboardHeight}px;
  position: fixed;
  bottom: 0;
  z-index: 500;
`;
const Dimmer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    background-color: rgba(60, 60, 60, 40%);
`;

const ModalContainer = styled.div`
    display: inline-block;
    padding: 1.5rem 1.5rem 2.8rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    max-width: ${theme.layout.mainWidth};
    background-color: #fff;
    border-radius: 20px;
    touch-action: none;
    transition: 0.15s ease-out;
    max-width: 400px;
`;

const ModalBodyContainer = styled.div`
    margin-top: 1.5rem;
    font-weight: 500;
`;

const ModalBody = styled.div`
    font-size: 1.4rem;
    text-align: center;
`;

const QRCodeContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const CloseButtonContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
`;

const CloseButton = styled.img`
    width: 3.5%;
    margin: 1rem;
    cursor: pointer;
`;

const QRCode = styled.img`
    width: 50%;
    margin-top: 1.5rem;
`;
